img{
	max-width: 100%;
	height: auto;
}


@import url("//hello.myfonts.net/count/313358");  
@font-face {font-family: 'Bell-Med';src: url('../../assets/fonts/313358_0_0.eot');src: url('../../assets/fonts/313358_0_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/313358_0_0.woff2') format('woff2'),url('../../assets/fonts/313358_0_0.woff') format('woff'),url('../../assets/fonts/313358_0_0.ttf') format('truetype');}
@font-face {font-family: 'Bell-Ita';src: url('../../assets/fonts/313358_1_0.eot');src: url('../../assets/fonts/313358_1_0.eot?#iefix') format('embedded-opentype'),url('../../assets/fonts/313358_1_0.woff2') format('woff2'),url('../../assets/fonts/313358_1_0.woff') format('woff'),url('../../assets/fonts/313358_1_0.ttf') format('truetype');}
 


*{box-sizing:border-box;}
html{font-family: 'Bell-Med'; letter-spacing: 1px; color: #1D1D1B; font-size: 16px}



img{max-width: 100%; height: auto; width:100%}

a{color: black; text-decoration: none;}

section.hero{text-align: center;}
.pagewidth{
	padding: 0 20px;
	max-width: 1000px;
	margin: auto;
}

.align-center{text-align: center;}
.inline-block{display: inline-block;}
.margin-auto{margin: auto}


section.page{max-width: 960px; margin: auto; padding:0 0.5em}

header{
	background-color: white; position: relative;z-index:1;  padding: 2em 0;
	h1{font-size: 1em; text-align: center; padding-bottom: 0.5em; text-transform: uppercase;}	

	nav{
		text-transform: uppercase;
		text-align: center;
		ul{margin:0; padding:0; font-size: 1em}
		li{display: inline-block; position: relative; margin-right: 0.5em; cursor: pointer;}

		&.fixed{
			position: fixed; top:0; left:0; width: 100%; opacity: 1; pointer-events:none; z-index: 1; transform: translateY(-100%);
			transition: all 0.15s ease-out; background-color: white; visibility: hidden;
			h1{padding-top: 1em}
			.menu-menyn-container{
				max-width: 960px; margin: auto; padding:0 0.5em 1em;  position: relative;
				.layout-buttons{right: 0.5em; bottom: 1em;}
			}
			&.show{visibility: visible; pointer-events:all; transform: translateY(0)};
		}
	}
} 


div.contact{
	position: static;
	display: none;
	text-align: center;
	letter-spacing: 0.1px;
	line-height: 1.3;
	//.half-width{white-space: pre-line;}
	padding-top:1.5em;
	&.show{display: block;}
	.half-width{
		padding-left: 8px;
	}
	
	a{border-bottom: 1px solid rgba(black, 0); transition: border-bottom 0.1s;}
	a:hover{
		border-bottom: 1px solid black;
	}
}
span.no-break{
	white-space: nowrap;
}

header nav li{

	&:after{
		width: 100%; height: 1.2px; background: #1D1D1B; bottom: -1px; left:0;
		content:""; display: inline-block; position: absolute; opacity: 0;
		transition: opacity 0.15s;
	}
	&.current-menu-item:after, &.active:after, &:hover:after{ opacity: 1; }
}


footer{
	text-align: center;
	margin-top: 4em;
	margin-bottom: 2em;
	position: relative;
	padding-top: 1em;
	border-top: 1px solid black;
	font-size: 90%;
	letter-spacing: 0.1px;
	a{border-bottom: 1px solid rgba(black, 0); transition: border-bottom 0.1s;}
	a:hover{
		border-bottom: 1px solid black;
	}
}


.dib{display: inline-block;}
.display-none{display: none}
.hidden{visibility: hidden;}


.grid-item{
	background-size:cover; height: auto; background-repeat: no-repeat; background-position: center;
	width: calc(25% - 6px); margin-bottom: 8px; display: inline-block;
};

.font-0{font-size: 0;}
.font-reset{font-size: 16px}


.fixer{width:100%; position: relative;}
.grid-sizer{width: calc(25% - 6px)}
.gutter-sizer{width: 8px}

.format-1{ 
	.fixer{padding-top: 65.66005177%}
	.fixer:after{padding-top: calc(32.5% - 0.25em);}
}
.format-2{
	.fixer{padding-top: 130.37809648%}	
	.fixer:after{padding-top: calc(65% - 0.25em);}
} 


.half-width{width:50%;}
.half-width-gutter{width:calc(50% - 4px)}
.full-width{width:100% !important}

.grid-item{transition: opacity .2s;}
.grid-item:hover{
	opacity: 0.9;
	
}
.fixer:after{content: attr(data-title); text-align: center; text-transform: uppercase;	word-wrap: break-word; font-size:1em; border: 1px solid black; box-sizing: border-box; background: white; opacity:0; top:0; left:0; position: absolute; width: 100%; height: 100%;
	transition: opacity .1s ease-out;
}

@media screen and (max-width: 1023px){
	.grid-item, .grid-sizer{width: calc(33.333333% - 5px)}
}


@media screen and (max-width: 767px){
	//.format-1, .format-2{padding-top: 80%}
	footer .textwidget{font-size: 0.8em; line-height: 1.4; white-space: pre}
	//section.hero{height: calc(100vh - 150px);}

	//header h1{padding-bottom: 1.5em}
	
	header nav .fixed ul{margin-bottom: 0};
	//header nav li{padding: 1em 0}

	html{font-size: 0.7em}

	.idha-gallery{background:white;}

	.gutter-sizer{width: 4px}
	
	.sharebuttons{
		top: 2px;
		font-size: 1.3em;
		a{margin-left: 0.6em}
	}

	.half-width{width: 100%}

	.grid-sizer, .grid-item{width: calc(50% - 2px)}
	.grid-item{margin-bottom: 4px}

	.layout-buttons div, header nav li{ 
		&:hover:after{
			opacity: 0;
		}
	}

	#baguetteBox-overlay .full-image{
		figcaption{
			span{top: 0.35em}
		}
	}

	div.contact{
		position: static;
		display: none;

		visibility: visible;
		//padding-left: 0.5em;
		.half-width{margin-bottom:1em;}
		//&.show{display: inline-block; visibility: visible !important;}
	}

	.baguetteBox-button {
  		&#next-button, &#previous-button { display: none;}
  	}

}




