/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.5.0
 * @url https://github.com/feimosi/baguetteBox.js
 */

.sharebuttons{position: relative; top: 5px; display: inline-block; float:right; font-size: 1em;}
.sharebuttons .socicon-facebook{font-size: 0.88em; position: relative; bottom: 1px}
.sharebuttons .socicon-twitter{font-size: 0.85em; position: relative; bottom: 1px}
.sharebuttons a{ display: inline-block; margin-left: 0.3em}

.share{
  font-size: 16px; height: 16px; width: 20px; margin-left:0.5em; display: inline-block;
  background-image:url('../img/svg/share-icons.svg'); background-repeat: no-repeat; background-size: 117.96px 16.02px; background-color: none; 
  &.fb{background-position: -21px}
  &.twitter{background-position: -45px}
  &.pinterest{background-position: 3px}
  &.gplus{background-position: -73px}
}

.closey{font-size:0; width:20px; height:20px;/* background-image: url(close.svg)*/;display: inline-block; position: absolute; top: 8px; right: 8px; cursor: pointer;}

#knapp-holder{
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  //background:rgba(black, 0.05);
  left: 50%;
  transition: width 0.2s ease-out;
  transform: translateX(-50%);
  pointer-events: none;
  button{
    cursor: pointer;
    pointer-events: all;
  }
}

#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
  &.visible {
    opacity: 1;
  }
  

  .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    


    figure {
      display: block;
      margin: 0;
      padding-bottom: 1.6em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
    img {
      
     
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 90vh;
      max-width: calc(100vw - 16px);
     
     // box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
    figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: left;
      padding: 0.2em 0.5em;


      span{background-color: none; padding: 0.3em 0; display: inline-block; position: relative; top: 0.1em}


      //line-height: 1.8;
      white-space: normal;
      //color: #ccc;t
      text-transform: uppercase;
      background-color: white;
      //background-color: rgba(0, 0, 0, 0.6);
      //font-family: sans-serif;
    }
    &:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px;
    }
  }
}

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  -webkit-transition: left .4s ease,-webkit-transform .4s ease;
  transition: left .4s ease,-moz-transform .4s ease;
  transition: left .4s ease,transform .4s ease;
  &.bounce-from-right {
    -webkit-animation: bounceFromRight .4s ease-out;
    animation: bounceFromRight .4s ease-out;
  }
  &.bounce-from-left {
    -webkit-animation: bounceFromLeft .4s ease-out;
    animation: bounceFromLeft .4s ease-out;
  }
}

@-webkit-keyframes bounceFromRight {
  0%,100% {  margin-left: 0; }

  50% {
    margin-left: -30px;
  }
}


@keyframes bounceFromRight {
  0%,100% {
    margin-left: 0;
  }

  50% {
    margin-left: -30px;
  }
}


@-webkit-keyframes bounceFromLeft {
  0%,100% {
    margin-left: 0;
  }

  50% {
    margin-left: 30px;
  }
}


@keyframes bounceFromLeft {
  0%,100% {
    margin-left: 0;
  }

  50% {
    margin-left: 30px;
  }
}


.baguetteBox-button {
  &#next-button, &#previous-button {
    top: 50%;
    top: calc(50% - 30px);
    width: 40px; height: 40px;
    background-image: url(../img/svg/nav-arrows.svg);
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
  }
  position: absolute;
  cursor: pointer; outline: 0; padding: 0; margin: 0; border: 0;
  
  
  font: 1.6em sans-serif;
  
  &#next-button {
    right: 2%;
    background-position: 100%;
  }
  &#previous-button {
    left: 2%;
  }
  &#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px;
  }
  svg {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.double-bounce1 {
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  width: 100%;
  height: 100%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes bounce {
  0%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes bounce {
  0%,100% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}